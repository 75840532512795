import colors from "colors";

export const navBarColorMapper = {
	purple: {
		font: colors.neutral["pure-50"],
		bg: colors.purplish["pure-500"],
		activeBg: colors.yellowish["high-50"],
		activeFont: colors.neutral["pure-500"]
	},
	yellow: {
		font: colors.neutral["pure-500"],
		bg: colors.yellowish["high-50"],
		activeBg: colors.purplish["pure-500"],
		activeFont: colors.neutral["pure-50"]
	},
	white: {
		font: colors.neutral["pure-500"],
		bg: colors.neutral["pure-50"],
		activeBg: colors.yellowish["high-50"],
		activeFont: colors.neutral["pure-500"]
	}
};
