import clsx from "clsx";
import colors from "colors";
import { navBarColorMapper } from "components/Navbar/types";
import { useState } from "react";
import "./styles.scss";

function NavLink({
	href,
	isActive,
	label,
	navBarColors = navBarColorMapper.purple,
	onClick
}: {
	href: string;
	isActive: boolean;
	label: string;
	navBarColors?: {
		font: string;
		bg: string;
		activeBg: string;
		activeFont: string;
	};
	onClick?: () => void;
}) {
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const linkStyle = {
		color: isHovered ? navBarColors.activeBg : navBarColors.font,
		transition: "color 0.3s ease"
	};

	return (
		<div
			className={clsx("link", { active: isActive })}
			style={linkStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div
				className={clsx({
					"yellow-active": navBarColors.bg === colors.yellowish["high-50"],
					"purple-active": navBarColors.bg === colors.purplish["pure-500"],
					"white-active": navBarColors.bg === colors.neutral["pure-50"]
				})}
			>
				<a href={href} onClick={onClick}>
					{label}
				</a>
			</div>
		</div>
	);
}

export default NavLink;
