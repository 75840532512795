import BaseLayout from "layouts/BaseLayout";
import { MainPage } from "pages";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

function Routers() {
	const { pathname } = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}, 100);
	}, [pathname]);

	return (
		<Routes>
			<Route path="/" element={<BaseLayout />}>
				<Route path="/" element={<MainPage />} />
			</Route>
			<Route path="/*" element={<Navigate to="/" />} />
		</Routes>
	);
}

export default Routers;
