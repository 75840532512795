import { IcoClose } from "assets/icons";
import clsx from "clsx";
import LanguageSwitch from "components/LanguageSwitch";
import NavLink from "components/NavLink";
import "./styles.scss";

function MobileMenu({
	isOpen,
	toggleMenu,
	currentSection,
	t,
	changeLanguage
}: {
	isOpen: boolean;
	toggleMenu: () => void;
	currentSection: string | null;
	t: (key: string) => string;
	changeLanguage: (lng: string) => void;
}) {
	return (
		<div className={clsx("mobile-menu", { open: isOpen })}>
			<button className="close-button" onClick={toggleMenu}>
				{}
				<IcoClose size="40" />
			</button>
			<div className="links-wrapper">
				<NavLink
					href="#sobre"
					isActive={currentSection === "sobre"}
					label={t("about")}
					onClick={toggleMenu}
				/>
				<NavLink
					href="#entregas"
					isActive={currentSection === "entregas"}
					label={t("deliveries")}
					onClick={toggleMenu}
				/>
				<NavLink
					href="#trabalhos"
					isActive={currentSection === "trabalhos"}
					label={t("works")}
					onClick={toggleMenu}
				/>
				<NavLink
					href="#contato"
					isActive={currentSection === "contato"}
					label={t("contact")}
					onClick={toggleMenu}
				/>
			</div>
			<LanguageSwitch changeLanguage={changeLanguage} />
		</div>
	);
}

export default MobileMenu;
