import { IcoBrand } from "assets/icons";
import { useWindowSize } from "hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

function WelcomeSection() {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const iconSize = useMemo(
		() => (width > 1165 ? "150" : width > 768 ? "100" : "50"),
		[width]
	);

	return (
		<section className="welcome-section" data-bgcolor="purple" id="">
			<div className="width--fit-content">
				<h1>
					<div className="first-line">
						<span className="font-title color--yellowish-pure-500">jacque</span>
						<div className="brand-icon">
							<IcoBrand theme="yellow" size={iconSize} />
						</div>
					</div>
					<span className="font-title color--neutral-pure-50">menassa</span>
				</h1>
				<p>| {t("strategic_planning")}</p>
			</div>
		</section>
	);
}

export default WelcomeSection;
