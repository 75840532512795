import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: {
					about: "about",
					deliveries: "deliveries",
					works: "works",
					contact: "contact",
					strategic_planning: "strategic planning",
					hello: "hello,",
					first_presentation_paragraph:
						"My name is Jacqueline Menassa, but you can call me Jacque.",
					second_presentation_paragraph:
						"I love new experiences and diving into different cultures, cuisines, and stories. I started my career in advertising as an account manager and also wrote articles for a news portal.",
					third_presentation_paragraph:
						"Throughout my journey, I have worked with clients like <strong>Natura, Vivo, Bauducco, Doriana, and Protea</strong>, always focusing on creating strategies that authentically connect brands and people."
				}
			},
			pt: {
				translation: {
					about: "sobre",
					deliveries: "entregas",
					works: "trabalhos",
					contact: "contato",
					strategic_planning: "planejamento estratégico",
					hello: "oie,",
					first_presentation_paragraph:
						"me chamo Jacqueline Menassa, mas pode me chamar de Jacque.",
					second_presentation_paragraph:
						"Adoro novas experiências e mergulhar em diferentes culturas, culinárias e histórias. Comecei minha carreira na publicidade como atendimento e também escrevi matérias para um portal de notícias.",
					third_presentation_paragraph:
						"Em minha trajetória, atendi clientes como <strong>Natura, Vivo, Bauducco, Doriana e Protea</strong>, sempre focando em criar estratégias que conectam marcas e pessoas de maneira autêntica."
				}
			}
		},
		fallbackLng: "pt",
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
