import { ImgJacque } from "assets/images";
import { Trans, useTranslation } from "react-i18next";
import "./styles.scss";

function AboutSection() {
	const { t } = useTranslation();

	return (
		<section className="about-section" data-bgcolor="white" id="sobre">
			<div className="about-wrapper">
				<div className="presentation">
					<h1>
						<div className="first-line">{t("hello")}</div>
					</h1>
					<div>
						<p>
							<Trans i18nKey="first_presentation_paragraph" />
						</p>
						<p>
							<Trans i18nKey="second_presentation_paragraph" />
						</p>
						<p>
							<Trans i18nKey="third_presentation_paragraph" />
						</p>
					</div>
				</div>
				<div className="image">
					<ImgJacque height="inherit" width="inherit" />
				</div>
			</div>
		</section>
	);
}

export default AboutSection;
