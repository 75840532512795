import colors from "colors";
import { IcoBrandProps } from "./types";

export default function IcoBrand(props: IcoBrandProps) {
	const {
		fullIcon = true,
		hasBackground = false,
		size = "1080",
		theme = "purple"
	} = props;
	const bgColor =
		theme === "yellow"
			? colors.purplish["pure-500"]
			: colors.yellowish["high-50"];
	const innerColor =
		theme === "yellow"
			? colors.yellowish["pure-500"]
			: colors.purplish["high-50"];
	return (
		<svg
			width={size}
			height={size}
			viewBox={`255 250 ${fullIcon ? "560" : 1080 / 2} 560`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_67_188)">
				{hasBackground && <circle cx="540" cy="540" r="445" fill={bgColor} />}
				<path
					opacity="1"
					d="M524.614 817.182V559.028L300.219 688.105L284.332 660.304L508.727 531.227L284.332 402.151L300.219 374.349L524.614 503.426V245.273H556.386V503.426L780.781 374.349L796.668 402.151L572.273 531.227L796.668 660.304L780.781 688.105L556.386 559.028V817.182H524.614Z"
					fill={innerColor}
				/>
				<circle cx="540.5" cy="536.5" r="55.5" fill={innerColor} />
			</g>
			<defs>
				<clipPath id="clip0_67_188">
					<rect width="1080" height="1080" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
