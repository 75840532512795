import colors from "colors";
import { LanguageSwitch, MobileMenu, NavLink } from "components";
import HamburgerMenu from "components/HamburguerMenu";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { navBarColorMapper as colorMapper } from "./types";

function Navbar() {
	const { t, i18n } = useTranslation();
	const changeLanguage = (lng: string) => i18n.changeLanguage(lng);

	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
	const [currentSection, setCurrentSection] = useState<string | null>(null);

	const [navBarColors, setNavBarColors] = useState({
		font: colors.neutral["pure-50"],
		bg: colors.purplish["pure-500"],
		activeBg: colors.yellowish["high-50"],
		activeFont: colors.neutral["pure-500"]
	});

	const handleScroll = () => {
		const sections = document.querySelectorAll("section");
		const { scrollY } = window;
		let currentNavBarColors = colorMapper.white;
		let currentSectionId: string | null = "";

		sections.forEach((section) => {
			const sectionTop = section.offsetTop;
			const sectionHeight = section.offsetHeight;
			const sectionBottom = sectionTop + sectionHeight;

			if (scrollY >= sectionTop && scrollY < sectionBottom - 90) {
				currentNavBarColors =
					colorMapper?.[
						(section.getAttribute("data-bgcolor") ||
							"white") as keyof typeof colorMapper
					];
			}

			if (
				scrollY >= sectionTop - sectionHeight / 4 &&
				scrollY < sectionTop + sectionHeight
			) {
				currentSectionId = section.getAttribute("id");
			}
		});

		setCurrentSection(currentSectionId);
		setNavBarColors(currentNavBarColors);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<nav className="navbar" style={{ backgroundColor: navBarColors.bg }}>
			<div className="navbar-desktop">
				<div className="links-wrapper">
					<NavLink
						href="#sobre"
						isActive={currentSection === "sobre"}
						label={t("about")}
						navBarColors={navBarColors}
						onClick={() => setCurrentSection("sobre")}
					/>
					<NavLink
						href="#entregas"
						isActive={currentSection === "entregas"}
						label={t("deliveries")}
						navBarColors={navBarColors}
						onClick={() => setCurrentSection("entregas")}
					/>
					<NavLink
						href="#trabalhos"
						isActive={currentSection === "trabalhos"}
						label={t("works")}
						navBarColors={navBarColors}
						onClick={() => setCurrentSection("trabalhos")}
					/>
					<NavLink
						href="#contato"
						isActive={currentSection === "contato"}
						label={t("contact")}
						navBarColors={navBarColors}
						onClick={() => setCurrentSection("contato")}
					/>
				</div>
				<LanguageSwitch
					changeLanguage={changeLanguage}
					fontColor={navBarColors.font}
				/>
			</div>
			<div className="navbar-mobile">
				<HamburgerMenu
					toggleMenu={toggleMobileMenu}
					color={navBarColors.font}
				/>
				<MobileMenu
					isOpen={isMobileMenuOpen}
					toggleMenu={toggleMobileMenu}
					currentSection={currentSection}
					t={t}
					changeLanguage={changeLanguage}
				/>
			</div>
		</nav>
	);
}

export default Navbar;
