const colors = {
	neutral: {
		"pure-50": "#FFFFFF",
		"pure-500": "#000000",
		transparent: "transparent"
	},
	yellowish: {
		"low-950": "#C6D095", // Dark yellow
		"pure-500": "#D8EA4A", // Base yellow
		"high-50": "#E4F082" // Light yellow
	},
	purplish: {
		"low-950": "#18186B", // Dark purple
		"pure-500": "#504FE1", // Base purple
		"high-50": "#8C8CB4" // Light purple
	},
	grayish: {
		"pure-50": "#D9D9D9",
		"low-500": "#ECECEC",
		"high-50": "#DCDCF9",
		"high-100": "#F5F5F5",
		"pure-500": "#DED6D6"
	}
};

module.exports = colors;
