import { Outlet } from "react-router-dom";

import { Navbar } from "components";

export default function BaseLayout() {
	return (
		<>
			<Navbar />
			<div className="outlet">
				<Outlet />
			</div>
		</>
	);
}
