import colors from "colors";
import "./styles.scss";

function HamburgerMenu({
	color = colors.neutral["pure-50"],
	toggleMenu
}: {
	color?: string;
	toggleMenu: () => void;
}) {
	return (
		<button className="hamburger-menu" onClick={toggleMenu}>
			{}
			<div className="bar" style={{ backgroundColor: color }} />
			<div className="bar" style={{ backgroundColor: color }} />
			<div className="bar" style={{ backgroundColor: color }} />
		</button>
	);
}

export default HamburgerMenu;
