import colors from "colors";
import "./styles.scss";

function LanguageSwitch({
	fontColor = colors.neutral["pure-50"],
	changeLanguage
}: {
	fontColor?: string;
	changeLanguage: (lng: string) => void;
}) {
	return (
		<div className="language-switch" style={{ color: fontColor }}>
			<button onClick={() => changeLanguage("pt")}>pt</button> |{" "}
			<button onClick={() => changeLanguage("en")}>en</button>
		</div>
	);
}

export default LanguageSwitch;
