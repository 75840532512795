import { AboutSection, WelcomeSection } from "components";
import "./styles.scss";

function MainPage() {
	return (
		<div className="main-page">
			<WelcomeSection />
			<AboutSection />
		</div>
	);
}

export default MainPage;
